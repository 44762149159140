<template>
  <WeContainer card="" v-if="ready">
    <Navigation
      v-on:save-form="submitForm"
      v-bind:submit="submit"
      v-bind:errors="errors"
    />
    <div class="tab-content">
      <div class="tab-pane fade show active">
        <div class="row align-items-stretch mb-xl-3">
          <div class="col-12 mb-3 col-xl mb-xl-0">
            <div class="card h-100">
              <div class="card-body">
                <!-- Name -->
                <WeInput
                  v-model="form.name"
                  v-bind:label="$t('shopName')"
                  v-bind:error="$v.form.name.$error"
                  v-bind:required="true"
                />
                <!-- ./Name -->

                <!-- Slug -->
                <WeSlugCheck
                  v-bind:value="form.name"
                  v-bind:id="$route.params.id"
                  v-bind:slug="form.slug"
                  v-bind:search="true"
                  v-on:slugify="form.slug = $event"
                  url-fragment="magaza"
                  type="shop"
                />
                <!-- ./Slug -->

                <div class="row">
                  <div class="col-12 col-md">
                    <!-- Phone -->
                    <WeInput v-model="form.email" v-bind:label="$t('email')" />
                    <!-- ./Phone -->
                  </div>
                  <div class="col-12 col-md">
                    <!-- Phone -->
                    <WeInput
                      v-model="form.phone"
                      v-bind:label="$t('phoneNumber')"
                    />
                    <!-- ./Phone -->
                  </div>
                </div>

                <!-- Address -->
                <div class="mb-3">
                  <div class="row">
                    <!-- City -->
                    <div class="col-12 col-md">
                      <div class="form-group">
                        <label for="city" class="custom-label">{{
                          $t("city")
                        }}</label>
                        <v-select
                          label="name"
                          v-bind:class="[
                            'custom-v-select-sm',
                            $v.form.city.$error ? 'v-select--error' : '',
                          ]"
                          v-bind:placeholder="$t('city')"
                          v-bind:options="address.cities"
                          v-bind:value="form.city"
                          v-on:input="changeCity"
                          v-bind:clearable="false"
                        >
                          <span slot="no-options">Gösterilecek sonuç yok</span>
                        </v-select>
                      </div>
                    </div>
                    <!-- ./City -->
                    <!-- District -->
                    <div class="col-12 col-md">
                      <div class="form-group">
                        <label for="city" class="custom-label">{{
                          $t("district")
                        }}</label>
                        <v-select
                          label="name"
                          v-bind:class="[
                            'custom-v-select-sm',
                            $v.form.district.$error ? 'v-select--error' : '',
                          ]"
                          v-bind:placeholder="$t('district')"
                          v-bind:options="address.districts"
                          v-bind:disabled="!form.city"
                          v-model="form.district"
                          v-bind:clearable="false"
                        >
                          <span slot="no-options">Gösterilecek sonuç yok</span>
                        </v-select>
                      </div>
                      <!-- District -->
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <label for="address" class="custom-label">{{
                      $t("address")
                    }}</label>
                    <textarea
                      name="address"
                      cols="30"
                      rows="10"
                      class="form-control address"
                      v-model="form.address"
                    ></textarea>
                  </div>
                </div>
                <!-- Address -->

                <!-- Location -->
                <WeInput
                  v-model="form.location"
                  label="Harita - Enlem, Boylam"
                />
                <!-- ./Location -->

                <!-- Image -->
                <WeImageUploader
                  v-model="form.image"
                  title="Görsel"
                  v-bind:fixedMetaTitle="form.name"
                  v-bind:fixedMetaAlt="form.name"
                />
                <!-- ./Image -->

                <!-- Image -->
                <WeImageUploader
                  v-model="form.cover_image"
                  title="Kapak Görseli"
                  v-bind:fixedMetaTitle="form.name"
                  v-bind:fixedMetaAlt="form.name"
                />
                <!-- ./Image -->
                <!-- Status -->
                <div class="mb-4">
                  <label class="custom-label">{{ $t("status") }}</label>
                  <WeSwitch
                    v-model="form.is_active"
                    v-bind:show-status="true"
                  />
                </div>
                <div class="row">
                  <div class="col-12">
                    <h4>Açılış/Kapanış Tarihleri</h4>
                    <hr />
                  </div>
                  <div
                    class="col-12 mb-3"
                    v-for="item in form.working_dates"
                    :key="item"
                  >
                    <div class="row">
                      <div class="col-5">
                        <WeDatePicker
                          :id="`date-picker-${item.name}-open`"
                          :label="`${item.name} Açılış`"
                          v-bind:format="'HH:mm'"
                          v-bind:output-format="'YYYY-MM-DD HH:mm'"
                          :only-time="true"
                          v-bind:locale="'tr'"
                          v-model="item.open"
                          :disabled="!item.isOpen"
                        />
                      </div>
                      <div class="col-5">
                        <WeDatePicker
                          :id="`date-picker-${item.name}-close`"
                          :label="`${item.name} Kapanış`"
                          v-bind:format="'HH:mm'"
                          v-bind:output-format="'YYYY-MM-DD HH:mm'"
                          :only-time="true"
                          v-bind:locale="'tr'"
                          v-model="item.close"
                          :disabled="!item.isOpen"
                        />
                      </div>
                      <div class="col-2">
                        <div class="mb-4">
                          <WeSwitch
                            v-model="item.isOpen"
                            :label="!item.isOpen ? 'Kapalı' : 'Açık'"
                            v-bind:show-status="true"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- ./Status -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </WeContainer>
  <WeLoading v-else />
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
const Navigation = () => import("./views/Navigation/Index");

export default {
  name: "Detail",
  data() {
    return {
      ready: false,
      submit: false,
      errors: [],
      form: {
        name: null,
        slug: null,
        email: null,
        phone: null,
        working_dates: [
          { name: "Pazartesi", open: new Date(), close: new Date(), isOpen: true },
          { name: "Salı", open: new Date(), close: new Date(), isOpen: true },
          { name: "Çarşamba", open: new Date(), close: new Date(), isOpen: true },
          { name: "Perşembe", open: new Date(), close: new Date(), isOpen: true },
          { name: "Cuma", open: new Date(), close: new Date(), isOpen: true },
          { name: "Cumartesi", open: new Date(), close: new Date(), isOpen: true },
          { name: "Pazar", open: new Date(), close: new Date(), isOpen: false },
        ],
        is_active: true,
        city: null,
        district: null,
        address: null,
        location: null,
        image: {
          files: [],
          filesData: null,
        },
        cover_image: {
          files: [],
          filesData: null,
        },
      },
      cleanRequest: {},
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      city: {
        required,
      },
      district: {
        required,
      },
    },
  },
  components: {
    Navigation,
  },
  methods: {
    ...mapActions("address", ["getCities", "getDistricts"]),
    ...mapActions("shop", ["create", "update", "getById"]),
    ...mapMutations("address", ["clearCities", "clearDistricts"]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    validateForm() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });

        return false;
      } else {
        return true;
      }
    },
    submitForm() {
      if (!this.validateForm()) {
        return;
      }
      this.submit = true;
      if (this.$route.params.id) {
        this.cleanRequest = helper.difference(this.form, this.cleanData, [
          "image",
          "cover_image",
          "working_dates",
        ]);
        this.updateShop();
      } else {
        this.addShop();
      }
    },
    addShop() {
      this.create({
        form: this.form,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showConfirmation("Detaya Git", result.data.id);
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    updateShop() {
      this.update({
        id: this.$route.params.id,
        form: this.cleanRequest,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showConfirmation("Sayfada Kal");
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    showConfirmation(cancelButtonText, id) {
      this.$swal({
        title: "İşlem Başarılı",
        text: "Mağaza listesine dönebilir veya bu sayfada kalabilirsiniz.",
        icon: "success",
        showCancelButton: true,
        cancelButtonText: cancelButtonText,
        confirmButtonText: "Liste Sayfasına Dön",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.redirectToList();
        } else {
          if (id) {
            this.$router.push(`detail/${id}`);
          } else {
            window.location.reload();
          }
        }
      });
    },
    getShopById() {
      if (this.$route.params.id) {
        this.getById({
          id: this.$route.params.id,
          onSuccess: (result) => {
            this.form = result;
            this.cleanData = helper.clone(this.form);
            this.ready = true;
          },
        });
      } else {
        this.ready = true;
      }
    },
    redirectToList() {
      this.$router.push("/shops");
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
    changeCity(data) {
      this.form.city = data;
      this.form.district = null;
      this.getDistricts({ id: data.id });
    },
  },
  computed: {
    ...mapState(["address", "shared"]),
    getTitle() {
      let result = this.$t("addShop");
      if (this.$route.params.id) result = this.$t("editShop");

      return result;
    },
    checkPermission() {
      return permission.check("shop", "u");
    },
  },
  mounted() {
    this.clearCities();
    this.clearDistricts();
    this.getCities({
      id: "G85jd70Qz",
      onFinish: () => {
        this.getShopById();
      },
    });
  },
};
</script>
<style lang="scss">
.address {
  max-height: 100px;
  resize: none;
}
</style>